import TemplateNames from "./TemplateNames";

export default {
  title: 'Automatisierte E-Mails',
  labels: {
    empty_list: 'Keine Vorlagen gefunden',
    on: 'An',
    off: 'Aus',
  },
  fields: {
    name: 'Name',
    messages_sent: 'Nachrichten gesendet',
    open_rate: 'Öffnungsrate',
    click_rate: 'Klickrate',
    status: 'Status',
  },
  template_names: TemplateNames,
  toasts: {
    failed_to_update_status: 'Status konnte nicht aktualisiert werden',
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  }
}