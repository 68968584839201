import FollowerDetails from "./FollowerDetails";

const followers = {
  title: 'Followers',
  fields: {
    name: 'Name',
    subscribed: 'Subscribed',
    followed: 'Followed',
    phone: 'Phone',
    recent_order: 'Recent Order',
    total_orders: 'Total Orders',
    birthday: 'Birthday',
    current_points: 'Current Points',
    google_review: 'Google Review',
  },
  empty_list: 'No customers yet',
  yes: 'Yes',
  no: 'No',
  buttons: {
    export: 'Export',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
  labels: {
    folowed_since_date: 'Since {{date}}',
  },
};

export default {
  ...followers,
  FollowerDetails,
};
