import ExternalDeliveries from './external-deliveries';
import Orders from './orders';
import Receipts from './receipts';
import UnpaidOrders from './unpaid-orders';

export default {
  ExternalDeliveries,
  Orders,
  Receipts,
  UnpaidOrders,
};
