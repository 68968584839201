import { 
  PAYMENT_METHODS, 
  EMAIL_MESSAGE_STATUSES,
} from '../../../../../../services/exports/Constants';
import TemplateNames from '../email-messages/TemplateNames';

export default {
  page_title: 'Follower',
  fields: {
    name: 'Name:',
    email: 'Email:',
    phone_number: 'Telefon:',
    subscribed: 'Abonniert:',
    birthday: 'Geburtstag:',
    location: 'Standort:',
    followed: 'Gefolgt:',
    lifetime_spent: 'Lebenslang € ausgegeben:',
    lifetime_points: 'Lebenslang Punkte:',
    current_points: 'Aktuelle Punkte:',
    total_orders: 'Bestellungen Gesamt:',
    google_review: 'Google Bewertung:',
  },
  labels: {
    yes: 'Ja',
    no: 'Nein',
    folowed_since_date: 'Seit {{date}}',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
  tabs: {
    recent_orders: {
      title: 'Letzte Bestellungen',
      columns: {
        order_id: 'Id',
        items: 'Artikel',
        total_amount: 'Gesamtbetrag',
        paid_on: 'Bezahlt am',
        payment_method: 'Zahlungsmethode',
      },
      labels: {
        no_orders: 'Noch keine Bestellungen',
      },
      payment_methods: {
        [PAYMENT_METHODS.CARD]: 'Kreditkarte',
        [PAYMENT_METHODS.PAYPAL]: 'PayPal',
        [PAYMENT_METHODS.APPLE_PAY]: 'Apple Pay',
        [PAYMENT_METHODS.GOOGLE_PAY]: 'Google Pay',
        [PAYMENT_METHODS.SOFORT]: 'Sofort',
        [PAYMENT_METHODS.GIROPAY]: 'Giropay',
        [PAYMENT_METHODS.SEPA_DEBIT]: 'Bank debit',
        [PAYMENT_METHODS.CASH]: 'Bargeld',
        [PAYMENT_METHODS.BACS_DEBIT]: 'Bank debit',
      },
    },
    messages: {
      title: 'Nachrichten',
      columns: {
        template: 'Vorlage',
        status: 'Status',
        opened: 'Geöffnet',
        clicked: 'Geklickt',
        spam_complaint: 'Spam Beschwerde',
        unsubscribed: 'Abgemeldet',
        sent_at: 'Gesendet am',
      },
      labels: {
        yes: 'Ja',
        no: 'Nein',
        not_detected: 'Nicht erkannt',
        opened_at: 'Geöffnet am {{date}}',
        clicked_at: 'Geklickt am {{date}}',
        complained_at: 'Beschwert am {{date}}',
        unsubscribed_at: 'Abgemeldet am {{date}}',
        no_messages: 'Noch keine Nachrichten',
      },
      message_statuses: {
        [EMAIL_MESSAGE_STATUSES.SENT]: 'Gesendet',
        [EMAIL_MESSAGE_STATUSES.DELIVERED]: 'Zugestellt',
        [EMAIL_MESSAGE_STATUSES.FAILED]: 'Fehlgeschlagen',
      },
      template_names: {
        ...TemplateNames,
      },
      toasts: {
        failed_to_load_data: 'Daten konnten nicht geladen werden',
      },
    },
  },
};
