export default {
  groups: {
    online_shop: 'Online Shop',
    marketing: 'Marketing',
    reservations: 'Reservierungen',
    storefront: 'Website',
    orders_portal: 'Start des Bestell-Portal',
    roi: 'ROI',
    seo: 'SEO',
  },
  get_started: {
    title: "Los geht's",
  },
  dashboard: {
    title: 'Dashboard',
  },
  new_company: {
    title: 'Neues Restaurant',
  },
  external_delivery_support: {
    title: 'Externe Lieferung Unterstützung',
  },
  stripe: {
    title: 'Stripe',
  },
  going_live_overview: {
    title: 'Live-Schaltung Übersicht',
  },
  going_live: {
    title: 'Live-Schaltung',
    children: {
      setup_package_fulfillments: 'Logsta-Erfüllungen',
      flyers: 'Flugblätter',
    },
  },
  store_overview: {
    title: 'Geschäftsübersicht',
  },
  franchises: {
    title: 'Restaurants',
  },
  storefront: {
    title: 'Website',
  },
  reservations: {
    title: 'Reservierungen',
    children: {
      overview: 'Übersicht',
      booking_availability: 'Buchungsverfügbarkeit',
      settings: 'Einstellungen',
      general: 'Allgemein',
      storefront: 'Storefront',
    },
  },
  seo: {
    title: 'Seo',
    children: {
      overview: 'Übersicht',
    },
  },
  subscriptions: {
    title: 'Abonnements',
    children: {
      subscriptions: 'Abonnements',
      products: 'Produkte',
    },
  },
  sales: {
    title: 'Bestellungen',
    children: {
      orders: 'Bestellungen',
      receipts: 'Rechnung für Bestellung',
      external_deliveries: 'Externe Lieferungen',
      unpaid_orders: 'Unbezahlte Bestellungen',
    },
  },
  billing: {
    title: 'Berichte',
    children: {
      monthly_statements: 'Monatliche Abrechnungsdokumente',
      payouts: 'Auszahlung',
      invoices: 'Rechnungen',
      subscription: 'Abonnement',
      internal_invoice_items: 'Interne Rechnungsposten',
      income_reports: 'Einkommensberichte',
    },
  },
  menu: {
    title: 'Menü',
    children: {
      manage_menus: 'Speisekarte',
      modifiers: 'Modifizierer',
      smart_pricing: 'Intelligente Preise',
      upsell_items: 'Upsell Artikel',
      sold_out_items: 'Ausverkaufte Artikel',
      import: 'Importieren',
    },
  },
  google_reviews: {
    title: 'Google Bewertungen',
  },
  my_store: {
    title: 'Meine Info',
    children: {
      settings: 'Profil',
      gallery: 'Gallerie',
      business_information: 'Geschäftliche Information',
      personal_information: 'Persönliche Information',
      bank_info: 'Bank Details',
      tax_info: 'Steuerinfo',
      shipping_info: 'Lieferadresse',
      google_profile: 'Google Profil',
    },
  },
  opening_hours: {
    title: 'Öffnungszeiten & Standort',
    children: {
      opening_hours: 'Öffnungszeiten',
      location: 'Standort',
    },
  },
  order_settings: {
    title: 'Bestell Einstellungen',
    children: {
      takeout: 'Bestellmethoden',
      dine_in: 'Dine in',
      payment_methods: 'Zahlungsarten',
      tips: 'Trinkgeld',
      receipt: 'Druck Bon',
      staff_credentials: 'Mitarbeiter Zugang',
      integrations: 'Integrationen',
      terminal: 'Terminal',
    },
  },
  marketing: {
    children: {
      overview: 'Übersicht',
      followers: 'Follower',
      email_messages: 'E-Mail-Nachrichten',
      promo_codes: 'Promo codes',
      google_business: 'Google Business',
      google_review_program: 'Google-Bewertungsprogramm',
      loyalty_program: 'Loyalitätsprogramm',
      flyers: 'Flugblätter',
    },
  },
  loyalty_tablet: {
    title: 'Loyalty Tablet',
    children: {
      dashboard: 'Dashboard',
      settings: 'Einstellungen',
      credentials: 'Credentials',
    },
  },
  contract: {
    title: 'Vertrag',
    children: {
      agreement: 'Vertrag',
      template: 'Template',
    },
  },
  team: {
    title: 'Team',
    children: {
      members: 'Mitglieder',
      reports: 'Verkaufsteam',
      signed_live: 'Unterzeichnet / Live',
    },
  },
};
