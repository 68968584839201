import TemplateNames from "./TemplateNames";

export default {
  title: 'Sent messages',
  columns: {
    recipient: 'Recipient',
    template: 'Template',
    status: 'Status',
    opened: 'Opened',
    clicked: 'Clicked',
    sent_at: 'Sent at',
  },
  labels: {
    empty_list: 'No sent messages yet',
    yes: 'Yes',
    no: 'No',
    clicked_at: 'Clicked at: {{date}}',
    opened_at: 'Opened at: {{date}}',
  },
  message_statuses: {
    sent: 'Sent',
    delivered: 'Delivered',
    failed: 'Failed',
  },
  template_names: TemplateNames,
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
}