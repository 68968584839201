import TemplateNames from "../email-messages/TemplateNames";

export default {
  template_names: TemplateNames,
  email_messages_page_title: 'Email Messages',
  labels: {
    view_full_message: 'View full message',
    status: 'Status:',
    on: 'On',
    off: 'Off',
  },
  tiles: {
    sends_count: 'Messages Sent',
    delivery_rate: 'Delivery Rate',
    delivered_suffix: 'delivered',
    open_rate: 'Open Rate',
    opened_suffix: 'opened',
    click_rate: 'Click Rate',
    clicked_suffix: 'clicked',
    spam_report_rate: 'Spam Report Rate',
    spam_reports_suffix: 'reports',
    unsubscribe_rate: 'Unsubscribe Rate',
    unsubscribes_suffix: 'unsubscribes',
  },
  toasts: {
    failed_to_update_status: 'Failed to update status',
    failed_to_load_data: 'Failed to load data',
  },

  preview_modal: {
    title: 'Template Preview',
    labels: {
      subject: 'Subject:',
      close: 'Close',
    },
    toasts: {
      failed_to_load_data: 'Failed to to load preview data',
    },
  },
};