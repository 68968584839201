import TemplateNames from "../email-messages/TemplateNames";

export default {
  title: "Marketing Übersicht",
  template_names: TemplateNames,
  toasts: {
    failed_to_load_data: "Daten konnten nicht geladen werden",
  },
  tiles: {
    total_followers: "Gesamtzahl der Follower",
    total_delivered_messages: "Gesamtzahl der zugestellten Nachrichten",
    average_open_rate: "Durchschnittliche Öffnungsrate",
    average_click_rate: "Durchschnittliche Klickrate",
    top_five_templates: "Top 5 Vorlagen",
  },
  labels: {
    opens: "Öffnungen",
  }
};