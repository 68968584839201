import ApiResourceManager from '../ApiResourceManager';

export default class MarketingEmailsManager extends ApiResourceManager {
  getMessages = (params) => this.request({
    method: 'GET',
    url: `/messages`,
    params,
  });

  getTemplates = () => this.request({
    method: 'GET',
    url: '/templates',
  });

  setTemplateActive = (id, isActive) => this.request({
    method: 'POST',
    url: `/templates/${id}/set-active`,
    data: { is_active: isActive },
  });

  getTemplate = (id) => this.request({
    method: 'GET',
    url: `/templates/${id}`,
  });

  getTemplatePreview = (id, templateData) => this.request({
    method: 'GET',
    url: `/templates/${id}/preview`,
    params: templateData,
  });

  getStats = () => this.request({
    method: 'GET',
    url: '/stats',
  });
}
