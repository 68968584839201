import TemplateNames from "../email-messages/TemplateNames";

export default {
  title: "Marketing overview",
  template_names: TemplateNames,
  toasts: {
    failed_to_load_data: "Failed to load data",
  },
  tiles: {
    total_followers: "Total followers",
    total_delivered_messages: "Total delivered messages",
    average_open_rate: "Average open rate",
    average_click_rate: "Average click rate",
    top_five_templates: "Top 5 templates",
  },
  labels: {
    opens: "opens",
  }
};