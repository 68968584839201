import FollowerDetails from "./FollowerDetails";

const followers = {
  title: 'Follower',
  fields: {
    name: 'Name',
    subscribed: 'Abonniert',
    followed: 'Gefolgt',
    phone: 'Telefon',
    recent_order: 'Letzte Bestellung',
    total_orders: 'Total Bestellungen',
    birthday: 'Geburtstag',
    current_points: 'Aktuelle Punkte',
    google_review: 'Google Review',
  },
  empty_list: 'Noch keine Kunden',
  yes: 'Ja',
  no: 'Nein',
  buttons: {
    export: 'Exportieren',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
  labels: {
    folowed_since_date: 'Seit {{date}}',
  },
};

export default {
  ...followers,
  FollowerDetails,
};
