export default {
  groups: {
    online_shop: 'Online Shop',
    marketing: 'Marketing',
    reservations: 'Reservations',
    storefront: 'Storefront',
    orders_portal: 'Launch orders portal',
    roi: 'ROI',
    seo: 'SEO',
  },
  get_started: {
    title: 'Get Started',
  },
  dashboard: {
    title: 'Dashboard',
  },
  new_company: {
    title: 'New restaurant',
  },
  external_delivery_support: {
    title: 'External delivery support',
  },
  stripe: {
    title: 'Stripe',
  },
  going_live_overview: {
    title: 'Going live Overview',
  },
  going_live: {
    title: 'Going live',
    children: {
      setup_package_fulfillments: 'Logsta fulfillments',
      flyers: 'Flyers',
    },
  },
  franchises: {
    title: 'Restaurants',
  },
  setup_package_fulfillments: {
    title: 'Setup package fulfillments',
  },
  storefront: {
    title: 'Storefront',
  },
  reservations: {
    title: 'Reservation',
    children: {
      overview: 'Overview',
      booking_availability: 'Booking Availability',
      settings: 'Settings',
      general: 'General',
      storefront: 'Storefront',
    },
  },
  seo: {
    title: 'Seo',
    children: {
      overview: 'Overview',
    },
  },
  subscriptions: {
    title: 'Subscriptions',
    children: {
      subscriptions: 'Subscriptions',
      products: 'Products',
    },
  },
  sales: {
    title: 'Sales',
    children: {
      orders: 'Orders',
      receipts: 'Customer receipts',
      external_deliveries: 'External deliveries',
      unpaid_orders: 'Unpaid orders',
    },
  },
  billing: {
    title: 'Reports',
    children: {
      monthly_statements: 'Monthly Statements',
      payouts: 'Payouts',
      invoices: 'Invoices',
      subscription: 'Subscription',
      internal_invoice_items: 'Internal invoice items',
      income_reports: 'Income reports',
    },
  },
  menu: {
    title: 'Menu',
    children: {
      manage_menus: 'Manage menus',
      modifiers: 'Modifiers',
      smart_pricing: 'Smart pricing',
      upsell_items: 'Upsell items',
      sold_out_items: 'Sold out items',
      import: 'Import',
    },
  },
  google_reviews: {
    title: 'Google reviews',
  },
  my_store: {
    title: 'My info',
    children: {
      settings: 'Profile',
      gallery: 'Gallery',
      business_information: 'Business Information',
      personal_information: 'Personal Information',
      bank_info: 'Bank Details',
      tax_info: 'Tax Details',
      shipping_info: 'Shipping Details',
      google_profile: 'Google profile',
    },
  },
  opening_hours: {
    title: 'Opening hours & Location',
    children: {
      opening_hours: 'Opening hours',
      location: 'Location',
    },
  },
  order_settings: {
    title: 'Order settings',
    children: {
      takeout: 'Order Methods',
      dine_in: 'Dine in',
      payment_methods: 'Payment methods',
      tips: 'Tips',
      receipt: 'Receipt',
      staff_credentials: 'Staff credentials',
      integrations: 'Integrations',
      terminal: 'Terminal',
    },
  },
  marketing: {
    children: {
      overview: 'Overview',
      followers: 'Followers',
      email_messages: 'Email messages',
      promo_codes: 'Promo codes',
      google_business: 'Google Business',
      google_review_program: 'Google review program',
      loyalty_program: 'Loyalty program',
      flyers: 'Flyers',
    },
  },
  loyalty_tablet: {
    title: 'Loyalty Tablet',
    children: {
      dashboard: 'Dashboard',
      settings: 'Settings',
      credentials: 'Credentials',
    },
  },
  contract: {
    title: 'Contract',
    children: {
      agreement: 'Agreement',
      template: 'Template',
    },
  },
  team: {
    title: 'Team',
    children: {
      members: 'Members',
      reports: 'Sales Team',
      signed_live: 'Signed / Live',
    },
  },
};
