import { 
  PAYMENT_METHODS, 
  EMAIL_MESSAGE_STATUSES,
} from '../../../../../../services/exports/Constants';
import TemplateNames from '../email-messages/TemplateNames';

export default {
  page_title: 'Followers',
  fields: {
    name: 'Name:',
    email: 'Email:',
    phone_number: 'Phone:',
    subscribed: 'Subscribed:',
    birthday: 'Birthday:',
    location: 'Location:',
    followed: 'Followed:',
    lifetime_spent: 'Lifetime spent:',
    lifetime_points: 'Lifetime points:',
    current_points: 'Current points:',
    total_orders: 'Total orders:',
    google_review: 'Google review:',
  },
  labels: {
    yes: 'Yes',
    no: 'No',
    folowed_since_date: 'Since {{date}}',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
  tabs: {
    recent_orders: {
      title: 'Recent orders',
      columns: {
        order_id: 'Id',
        items: 'Items',
        total_amount: 'Total amount',
        paid_on: 'Paid on',
        payment_method: 'Payment method',
      },
      labels: {
        no_orders: 'No orders yet',
      },
      payment_methods: {
        [PAYMENT_METHODS.CARD]: 'Card',
        [PAYMENT_METHODS.PAYPAL]: 'PayPal',
        [PAYMENT_METHODS.APPLE_PAY]: 'Apple Pay',
        [PAYMENT_METHODS.GOOGLE_PAY]: 'Google Pay',
        [PAYMENT_METHODS.SOFORT]: 'Sofort',
        [PAYMENT_METHODS.GIROPAY]: 'Giropay',
        [PAYMENT_METHODS.SEPA_DEBIT]: 'Bank debit',
        [PAYMENT_METHODS.CASH]: 'Cash',
        [PAYMENT_METHODS.BACS_DEBIT]: 'Bank debit',
      },
    },
    messages: {
      title: 'Messages',
      columns: {
        template: 'Template',
        status: 'Status',
        opened: 'Opened',
        clicked: 'Clicked',
        spam_complaint: 'Spam complaint',
        unsubscribed: 'Unsubscribed',
        sent_at: 'Sent at',
      },
      labels: {
        yes: 'Yes',
        no: 'No',
        not_detected: 'Not detected',
        opened_at: 'Opened at {{date}}',
        clicked_at: 'Clicked at {{date}}',
        complained_at: 'Complained at {{date}}',
        unsubscribed_at: 'Unsubscribed at {{date}}',
        no_messages: 'No messages yet',
      },
      message_statuses: {
        [EMAIL_MESSAGE_STATUSES.SENT]: 'Sent',
        [EMAIL_MESSAGE_STATUSES.DELIVERED]: 'Delivered',
        [EMAIL_MESSAGE_STATUSES.FAILED]: 'Failed',
      },
      template_names: {
        ...TemplateNames,
      },
      toasts: {
        failed_to_load_data: 'Failed to load data',
      },
    },
  },
};
